.info-container {
  width: 100%;
  height: 150px;
  background-color: #192326;
  background-image: url("../images/brand.png");
  color: white;
  margin-bottom: 100vh;
}

.info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width: 960px) {
  .info-container {
    height: 110px;
    width: 0px;
  }

  .info > h1 {
    text-align: center;
    font-size: 25px;
  }
}
