.pricing-section {
  width: 100%;
  height: 100%;
  background-color: #fff;
  text-align: center;
  margin-bottom: 2rem;
}

.pricing-section > p {
  color: red;
}
