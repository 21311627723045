@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,700,400italic);

$blue: #09518f;
$bgDark: #171b23;
$blueBorder: #1074cb;
$jumbotronBlue: #2a313a;
$gray: #5d5d5d;
$base-gray: #f4f4f4;
$mediumGray: #e3e4e6;
$jumbotronGray: #6a6a6a;
$border-gray: #d9dee5;
$jumbotron-text-gray: #6f7782;
$jumbotron-text-white: white;
$textDarkColor: #676a6c;
$textLightColor: #a0a5a8;
$progress-green: #91fd24;
$light-blue: #00acee;
$red: #eb4d5c;
$about-section-heading-blue: #074e8c;

.about-main {
  font-family: "Open Sans", sans-serif;
  display: flex;
  background-color: white;
  color: white;
  height: 300px;
  padding: 100px 0 100px 0;
  text-align: center;

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .jumbotron {
    width: 100%;
    height: 300px;
    padding-top: 40px;
    padding-bottom: 40px;
    background: $bgDark;
    position: relative;
    margin: 0;
    z-index: 3;
    border-radius: 0px;
  }
  .about-span {
    color: #91fd24;
    font-family: "Catamaran", sans-serif;
  }
  .img-layer-container {
    position: relative;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    img {
      max-width: 95vw;
    }
  }
  .team-image {
    position: absolute;

    img {
      position: relative;
      z-index: 3;
    }
  }
}
@media screen and (max-width: 960px) {
  .about-main {
    font-family: "Open Sans", sans-serif;
    display: flex;
    background-color: white;
    color: white;
    height: 300px;
    padding: 100px 0 100px 0;
    text-align: center;
    .jumbotron {
      width: 100%;
      height: 300px;
      padding-top: 5px;
      padding-bottom: 40px;
      background: $bgDark;
      position: relative;
      margin: 0;
      z-index: 3;
      border-radius: 0px;
    }
  }
}
