/* Global */
:root {
  /* Color */
  --color-white: #ffffff;
  --color-light-white: #eeeeee;
  --color-dark-white: #bdbdbd;
  --color-dark-grey: #20323b;
  --color-primeg: #91fd24;
  --color-beige: #efefef;
  --color-red: #cd2122;

  /* Font size */
  --font-large: 48px;
  --font-medium: 28px;
  --font-regular: 18px;
  --font-small: 16px;
  --font-micro: 14px;

  /* Font weight */
  --weight-bold: 700;
  --weight-semi-bold: 600;
  --weight-regular: 400;

  /* Size */
  --size-border-radius: 4px;

  /* Annimation */
  --animation-duration: 300ms;
}

/* Universal tags */
* {
  box-sizing: border-box;
}

/* Typography */
h1 {
  font-size: var(--font-large);
  font-weight: var(--weight-bold);
  color: var(--color-black);
  margin: 16px 0px;
}

h2 {
  font-size: var(--font-medium);
  font-weight: var(--weight-semi-bold);
  color: var(--color-black);
  margin: 8px 0;
}

h3 {
  font-size: var(--font-regular);
  font-weight: var(--font-regular);
  color: var(--color-black);
  margin: 8px 0;
}

p {
  font-size: var(--font-regular);
  font-weight: var(--font-regular);
  color: var(--color-black);
  margin: 4px 0;
}

body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--color-white);
}

ul {
  padding-left: 0;
}

li {
  list-style: none;
}

button {
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
}

/*#########################          Navbar           ##############################*/

.navbar {
  background-color: black;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  position: sticky;
  top: 0;
  z-index: 20;
}
.nav-linker {
  padding-right: 0.5rem;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  height: 80px;
  max-width: 1500px;
}

.nav-logo {
  color: var(--color-white);
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 18px;
  flex-grow: 1;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  font-family: "Montserrat", sans-serif;
}
.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #ffdd40;
}

.nav-item .active {
  color: #ffdd40;
  border: 1px solid #ffdd40;
}

.nav-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 60px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
  }

  .nav-menu.active {
    background: black;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #ffdd40;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.3rem;
    cursor: pointer;
    color: #ffdd40;
  }
}
@media screen and (max-width: 560px) {
  .nav-linker > a {
    font-size: 8px;
  }
  .nav-logo {
    color: #f5b921;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.2rem;
    flex-grow: 1;
  }
}
/*SectionTile*/
.tile {
  background: url("../images/bgbg4.jpg") center center/cover no-repeat;
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  color: var(--color-light-white);
}
.tile2 {
  background: url("../images/bgbg6.jpg") center center/cover no-repeat;
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  color: var(--color-light-white);
}
.tile3 {
  background: url("../images/bgbg6.jpg") center center/cover no-repeat;
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  color: var(--color-light-white);
}

/*#########################          Home           ##############################*/
.section {
  background: url("../images/bgbg3.jpg") center center/cover no-repeat;
  animation: move 20s ease-in;
  height: 470px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  color: white;
  align-items: baseline;
  padding-left: 50px;
}

.home-intro {
  width: 350px;
  text-align: right;
}
.some-page-wrapper {
  margin: 15px;
  width: 100%;
}

.letters {
  position: relative;
  bottom: 80px;
  right: 20px;
  color: white;
}
.red-button {
  background-color: rgb(235, 0, 41);
  width: 400px;
  height: 100px;
  transform: skew(30deg);
}
.red-button > h2 {
  color: #fff;
  margin-top: 0px;
  margin-bottom: 0px;
  transform: skew(-30deg);
}
.white-button {
  background-color: #fff;
  width: 400px;
  height: 100px;
  transform: skew(30deg);
  margin-left: 1.5rem;
  margin-top: -1rem;
}
.white-button > h2 {
  color: #000;
  margin-top: 0px;
  margin-bottom: 0px;
  transform: skew(-30deg);
}
.black-button {
  background-color: #000;
  width: 400px;
  height: 90px;
  transform: skew(30deg);
  margin-left: 1.5rem;
  margin-top: -0.5rem;
}
.black-button > h2 {
  color: #fff;
  margin-top: 0px;
  margin-bottom: 0px;
  transform: skew(-30deg);
}

@media screen and (max-width: 960px) {
  .red-button {
    background-color: rgb(235, 0, 41);
    width: 280px;
    height: 70px;
    transform: skew(30deg);
  }
  .white-button {
    background-color: #fff;
    width: 330px;
    height: 55px;
    transform: skew(30deg);
    margin-left: -1.5rem;
    margin-top: -2rem;
  }
  .black-button {
    background-color: black;
    width: 240px;
    height: 60px;
    transform: skew(30deg);
  }
  .red-button > h2 {
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
    transform: skew(-30deg);
    font-size: 1.5rem;
    margin-left: -1.5rem;
    margin-top: -1.3rem;
  }
  .white-button > h2 {
    color: #000;
    margin-top: 0px;
    margin-bottom: 0px;
    transform: skew(-30deg);
    font-size: 1.5rem;
  }
  .black-button > h2 {
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
    transform: skew(-30deg);
    font-size: 1.5rem;
  }
}

/*#########################          Boxtile           ##############################*/

.Section-boxtile {
  background-color: var(--color-dark-grey);
  filter: grayscale(60%);
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  color: var(--color-dark-white);
}
/*#########################          Cartils           ##############################*/
.Cartiles {
  width: 100%;
  height: 100px;
  background-image: url("../images/cartils.png");
}
.title-h1 {
  text-align: center;
  margin-top: 40px;
}

.Howitworkd {
  height: 800px;
  padding-top: 100px;
}

.Howitwork-container {
  width: 80%;
  height: 400px;
  background: aqua;
  margin: auto;
  padding: 10px;
}

.one {
  width: 30%;
  height: 500px;
  background: red;
  float: left;
}

.two {
  margin-left: 15%;
  height: 500px;
  background: black;
}

* {
  box-sizing: border-box;
}

/*#########################          Diyintro           ##############################*/
.diyintro {
  background: url("../images/bgbg.jpg") center center/cover no-repeat;
}

.scroll-to-top {
  position: fixed;
  bottom: 0;
  left: 0;
}

.arrow-btn {
  margin-right: 2rem;
}
