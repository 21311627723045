.info-ct {
  background-color: #fff;
  text-align: center;
}
h1 {
  font-family: "Catamaran", sans-serif;
}
h3 {
  font-family: "Catamaran", sans-serif;
}
.info-ct > h1 {
  background-color: #fff;
  margin: 0 0 0 0;
  padding-top: 5rem;
  padding-bottom: 2rem;
}
.container :after {
  content: "";
  display: table;
  clear: both;
  align-items: center;
}
.a,
.b,
.c,
.d {
  float: left;
  width: 50%;
  padding: 10px;
  height: 450px;
  font-size: 30px;
  text-align: center;
  padding-top: 2rem;
  z-index: -1;
}

.a {
  background: url("../images/1.png") center center/cover no-repeat;
  color: #fff;
}

.b {
  background: url("../images/22.png") center center/cover no-repeat;
  color: #fff;
}

.c {
  background: url("../images/c.jpg") center center/cover no-repeat;
  color: #fff;
}
.d {
  background: url("../images/boxtile-bg.png") center center/cover no-repeat;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .a,
  .b {
    width: 100%;
  }

  .c,
  .d {
    width: 100%;
  }
}
